/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/* Material Design Lite */

// Variables and mixins
@import "mdl/variables";
@import "mdl/mixins";

// Resets and dependencies
//@import "mdl/resets/resets";
//@import "mdl/typography/typography";

// Components
@import "mdl/palette/palette";
// @import "mdl/ripple/ripple";
// @import "mdl/animation/animation";
@import "mdl/badge/badge";
@import "mdl/button/button";
//@import "mdl/card/card";
@import "mdl/checkbox/checkbox";
// @import "mdl/chip/chip";
@import "mdl/data-table/data-table";
@import "mdl/dialog/dialog";
// @import "mdl/footer/mega_footer";
// @import "mdl/footer/mini_footer";
// @import "mdl/icon-toggle/icon-toggle";
// @import "mdl/list/list";
// @import "mdl/menu/menu";
// @import "mdl/progress/progress";
// @import "mdl/layout/layout";
@import "mdl/radio/radio";
// @import "mdl/slider/slider";
// @import "mdl/snackbar/snackbar";
// @import "mdl/spinner/spinner";
// @import "mdl/switch/switch";
//@import "mdl/tabs/tabs";
@import "mdl/textfield/textfield";
// @import "mdl/tooltip/tooltip";
@import "mdl/shadow/shadow";
//@import "mdl/grid/grid";
